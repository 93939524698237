.Log{
    color: white;
    transition: background-color 0.3s ease-in-out;
}
.Log:hover{
    background-color:black;
    transform: scale(1.1);
}
.Sign{
    color: white;
    transition: background-color 0.3s ease-in-out;
}
.Sign:hover{
    background-color:black;
    transform: scale(1.1);
}

.sty1 {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }
  
  .sty1:hover {
    transform: scale(1.2);
  }
  
