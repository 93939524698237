.SignUp{
    background-image: url("s.png");
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 100vh;
    height: 100vh;
    padding: 2% 0% 0% 0%;
    background-color: rgba(255, 0, 0, 0.5)
}

.TextField-error {
    animation: shake 0.5s ease-in-out;
  }
  
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
      transform: translateX(-5px);
    }
    20%, 40%, 60%, 80% {
      transform: translateX(5px);
    }
  }
  
  .button:hover {
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .Con{
    background-color: rgba(252, 246, 249, 0.559);
  }