.req{
    
    
    background-image: url("rq.png");
    min-width: 100vh;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2% 0% 0% 0%;
}

.C{
    background-color: rgba(247, 247, 247, 0.66);
    background-size: cover;
}
.Con{
    background-color: rgba(244, 242, 242, 0.671);
    background-size: cover;
}