
.cls4{
  background-image: url("addbg.png");
  background-size: cover;
  height: 100vh;
  width: 100%;
}
.cls1 {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0 auto;
    margin-top: 0;
  }
  
  .cls2 {
    margin-bottom: 16px;
  }
  
  .cls3 {
    margin-top: 16px;
  }
  .con{
    background-color: rgba(253, 237, 242, 0.766);
  }
  

  